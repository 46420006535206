<template>
  <div class="wrapper">
    <div class="p-5 top-bar">
      <img src="../../assets/img/logo-kanggo.png" alt="logo-kanggo" class="logoKanggo">
    </div>
    <div class="columns p-6">
      <div class="column is-5">
        <div>
          <p class="averta-bold is-size-5">Silakan ubah password kamu</p>
          <p class="mb-5">Agar data kamu lebih aman dan mudah untuk diingat</p>
          <p>Email terdaftar</p>
          <p class="averta-bold mb-6">{{ user.email }}</p>
          <div>
            <div class="mb-5">
              <p class="mb-2">Password<span class="has-text-danger-dark ml-1">*</span></p>
              <b-field>
                <b-input type="password" password-reveal v-model="password">
                </b-input>
              </b-field>
            </div>
            <div class="mb-5">
              <p class="mb-2">Konfirmasi Password<span class="has-text-danger-dark ml-1">*</span></p>
              <b-field>
                <b-input type="password" password-reveal v-model="confirmPassword">
                </b-input>
              </b-field>
            </div>
            <div v-if="showNotif" class="is-flex notif p-3 mb-5 is-align-items-center">
              <div class="card-attention has-text-centered">
                <span class="averta-bold">!</span>
              </div>
              <p>{{ messageFailedLogin }}</p>
            </div>
            <b-button class="btn-change-password" @click="changeDefaultPassword()">Ubah Password</b-button>
          </div>
        </div>
      </div>
      <div class="column is-flex is-justify-content-center is-hidden-mobile">
        <img src="../../assets/img/image-password.png" alt="image-password">
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'ChangeDefaultPassword',
  data () {
    return {
      showNotif: false,
      messageFailedLogin: '',
      password: null,
      confirmPassword: null
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      user: 'auth/user'
    })
  },
  methods: {
    changeDefaultPassword () {
      if (this.password === null || this.confirmPassword === null) {
        this.showNotif = true
        this.messageFailedLogin = 'Silakan masukkan password dan konfirmasi password terlebih dahulu'
        return false
      }

      if (this.password !== this.confirmPassword) {
        this.showNotif = true
        this.messageFailedLogin = 'Maaf, password dan konfirmasi password harus sama'
        return false
      }

      const payload = {
        password: this.password,
        confirmPassword: this.confirmPassword,
        url: this.url
      }

      this.$store
        .dispatch('auth/changePasswordDefault', payload)
        .then(response => {
          this.$buefy.toast.open({
            message: '<p class="averta-regular">Berhasil ubah default password</p>',
            type: 'is-success'
          })
          const dataLocalStorage = localStorage.getItem('webapp_shell_kanggo')
          const auth = JSON.parse(dataLocalStorage)
          if (auth.user.roles[0].name === 'Account Manager') {
            this.$router.push({ path: '/am/dashboard' })
          } else if (auth.user.roles[0].name === 'Site Manager') {
            this.$router.push({ path: '/sm/dashboard' })
          } else if (auth.user.roles[0].name === 'Purchasing') {
            this.$router.push({ path: '/purchasing/dashboard' })
          } else {
            this.$router.push({ path: '/' })
          }
        })
        .catch(error => {
          console.log(error)
          this.showNotif = true
          this.messageFailedLogin = error.response.data.message[0].message || error.response.data.message
          return false
        })
    }
  },
  mounted () {
    const rs = localStorage.getItem('webapp_shell_kanggo')
    const auth = JSON.parse(rs)
    if (auth.user.is_default_password === 0) {
      this.$router.push({ path: '/' })
    }
  }
})

</script>

<style lang="scss" scoped>
.wrapper {
  margin: 0;
  padding: 0;
  background: #FAFDFF;
  height: 100vh;
  width: 100vw;
}

.top-bar {
  background: white;
}

.logoKanggo {
  width: 12%;
}

.btn-change-password {
  background: #D9272D;
  border-radius: 90px;
  font-family: 'Averta-Bold';
  color: white;
  padding: 0px 26px;
}

.notif {
  background: #FFF1F1;
  border-radius: 20px;
}

.card-attention {
  width: 24px;
  height: 24px;
  background: linear-gradient(116.12deg, #D9272D 16.15%, #EB4600 83.24%);
  border-radius: 100px;
  color: white;
  margin: 0px 10px 0px 10px;
}

@media only screen and (max-width: 768px) {
  .wrapper {
    height: 120vh;
  }

  .top-bar {
    display: flex;
    justify-content: center;
  }

  .logoKanggo {
    width: 45%;
  }

  .card-attention {
    width: 72px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
